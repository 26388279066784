;globalThis["_sentryRewritesTunnelPath"] = "/monitoring";globalThis["SENTRY_RELEASE"] = {"id":"1c6fd439800aab33a9abdac614551c8d0f6dbf4c"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "/fem/1c6fd439800aab33a9abdac614551c8d0f6dbf4c";import * as Sentry from '@sentry/nextjs'
import {getEnvironment} from 'sentry.utils'
import {env} from '~/config/env.mjs'
import {isProd} from '~/config/nodeEnv'

Sentry.init({
  dsn: env.NEXT_PUBLIC_SENTRY_DSN,
  debug: false,
  enabled: isProd,
  environment: getEnvironment(),
})
